import React, { useState } from "react";
import "./signUp.css";
import logo from "../images/techbud_final_small.png";
import axios from "axios";
import { Link } from "react-router-dom";
import { Button } from "@material-ui/core";

function SignUp() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [password2, setPassword2] = useState("");
  const apikey =
    "55EEB1617892E6AEB437D540C875AE098C03D95457C6F3D897AF6DC82639FBB4A3DE23D6EB11A06A954E438FF34E2BA5";
  const signUp = async (e) => {
    console.log("clicked");
    e.preventDefault();
    await axios
      .post(
        `https://campaigns.techbud.in/v2/account/addsubaccount?apikey=${apikey}&email=${email}&password=${password}&confirmPassword=${password2}&requiresEmailCredits=true&maxContacts=0&sendActivation=false&returnUrl=https://campaigns.techbud.in/&emailSizeLimit=10&dailySendLimit=10000`
      )
      .then((res) => {
        console.log(res);
        res.data.success
          ? window.location.replace("https://campaigns.techbud.in")
          : console.log("nothing");
      });
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    console.log(email);
  };

  const onChangePassword = (e) => {
    setPassword(e.target.value);
    console.log(password);
  };

  const onChangePassword2 = (e) => {
    setPassword2(e.target.value);
    console.log(password2);
  };

  return (
    <div>
      <div class="background-su">
        <div class="shape"></div>
        <div class="shape"></div>
      </div>
      <form className="signupForm" onSubmit={signUp}>
        <center>
          <img src={logo} alt="logo" width="200 vw" />
        </center>
        <h4>SignUp Here</h4>

        <label for="username" className="signupLabel">
          Email
        </label>
        <input
          type="text"
          placeholder="Email"
          id="username"
          className="input1"
          onChange={onChangeEmail}
        />

        <label for="password" className="signupLabel">
          Password
        </label>
        <input
          type="password"
          placeholder="Password"
          id="password"
          className="input1"
          onChange={onChangePassword}
        />
        <label for="password" className="signupLabel">
          Confirm Password
        </label>
        <input
          type="password"
          placeholder="Confirm Password"
          id="password2"
          className="input1"
          onChange={onChangePassword2}
        />

        <button className="signupButton" type="submit">
          SignUp
        </button>
        <div class="login">
          Already a user?{" "}
          <a href="https://campaigns.techbud.in">
            {" "}
            <Button>Login Here</Button>
          </a>
        </div>
        {/* <div class="social">
      <div class="go"><i class="fab fa-google"></i>  Google</div>
      <div class="fb"><i class="fab fa-facebook"></i>  Facebook</div>
    </div> */}
      </form>
    </div>
  );
}

export default SignUp;
