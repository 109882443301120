import React from 'react'
import Header from '../components/headers/dark'
import Card from '../components/features/DashBoardScene'
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

function DashBoard() {
    return (
        <AnimationRevealPage>
            <Header/>
            <Card/>
            
        </AnimationRevealPage>
    )
}

export default DashBoard
