import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Helmet } from "react-helmet";

import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLinks,
  NavLink as NavLinkBase,
  PrimaryLink as PrimaryLinkBase,
} from "../headers/light.js";
import "./dropdown.css";
import {
  Container as ContainerBase,
  ContentWithVerticalPadding,
  Content2Xl,
} from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import logoImageSrc from "images/white_techbud_final_small2.png";
import serverIllustrationImageSrc from "images/server-illustration-2.svg";

const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-gray-100`;
const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(
  NavLinkBase
)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-gray-300`;
const PrimaryLink = tw(
  PrimaryLinkBase
)`shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;

const Container = tw(ContainerBase)``;
const Row = tw.div`flex items-center flex-col lg:flex-row z-0`;
const Column = tw.div`lg:w-1/2`;
const TextColumn = tw.div`text-center lg:text-left`;
const IllustrationColumn = tw(Column)`mt-16 lg:mt-0 lg:ml-16`;
const Heading = tw(
  SectionHeading
)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(
  SectionDescription
)`mt-4 max-w-2xl text-gray-100 lg:text-base mx-auto lg:mx-0`;
const PrimaryButton = tw(
  PrimaryButtonBase
)`mt-8 text-sm sm:text-base px-6 py-5 sm:px-10 sm:py-5 bg-primary-400 inline-block hocus:bg-primary-500`;
const Image = tw.img`w-144 ml-auto`;

export default ({
  heading = "Why limit yourself and the number of emails you can send?",
  description = "At TechBud, we only charge based on the contacts you have. Enjoy unlimited emails per contact and send as many marketing emails as you want.",
  primaryButtonText = "Contact Us",
  primaryButtonUrl = "./contact-us",
  imageSrc = serverIllustrationImageSrc,
}) => {
  const logoLink = (
    <LogoLink href="/">
      <img src={logoImageSrc} alt="Logo" height="20px" />
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/about-us">About</NavLink>
      <div class="dropdown">
        <button class="dropbtn">Our Products</button>
        <div class="dropdown-content">
          <a href="/email-marketing">Email Marketing</a>
          <a href="/website-development">Website Development</a>
        </div>
      </div>
      <NavLink href="/blogs">Blog</NavLink>
      <NavLink href="/contact-us">Contact Us</NavLink>
      <PrimaryLink href="/dashboard">DashBoard</PrimaryLink>
    </NavLinks>,
  ];
  return (
    <PrimaryBackgroundContainer>
      <Helmet>
        <title>Email Marketing Software | Unlimited Emails | TechBud</title>
        <meta
          name="Best Email Marketing Platform to reach your customers and interact with them. No limit on number of emails"
          content="TechBud assists you in sending emails campaigns to your customers so that you can be constant touch with them"
        />
        <meta property="og:site_name" content="TechBud" />
        <meta
          property="og:title"
          content="Best Email Marketing Platform to reach your customers and interact with them. No limit on number of emails"
        />
        <meta
          property="og:description"
          content="TechBud assists you in sending emails campaigns to your customers so that you can be constant touch with them"
        />
        <meta
          property="og:image"
          content="https://techbud.in/techbud_final_small.png"
        />
      </Helmet>
      <Content2Xl>
        <Header logoLink={logoLink} links={navLinks} />
        <Container>
          <ContentWithVerticalPadding>
            <Row>
              <TextColumn>
                <Heading>{heading}</Heading>
                <Description>{description}</Description>
                <PrimaryButton as="a" href={primaryButtonUrl}>
                  {primaryButtonText}
                </PrimaryButton>
              </TextColumn>
              <IllustrationColumn>
                <Image src={imageSrc} />
              </IllustrationColumn>
            </Row>
          </ContentWithVerticalPadding>
        </Container>
      </Content2Xl>
    </PrimaryBackgroundContainer>
  );
};
