import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import Hero from "components/hero/TwoColumnWithPrimaryBackground.js";
import Features from "components/features/ThreeColWithSideImageWithPrimaryBackground.js";
import MainFeature from "components/features/TwoColWithButton.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";
import Testimonial from "components/testimonials/SimplePrimaryBackground.js";
import Footer from "components/footers/FiveColumn.js";
import serverRedundancyIllustrationImageSrc from "images/server-redundancy-illustration.svg";
import serverSecureIllustrationImageSrc from "images/server-secure-illustration.svg";

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <Features
        heading="Design beautiful campaigns"
        description="Easily create emails with our drag-and-drop builder or use our pre-designed templates."
        cards={[
          {
            imageSrc: "",
            title: "Smart Segmentation",
            description:
              "Improve your conversions or sales by targeting a smaller subset of your contacts to align content with exactly what they need.",
          },
          {
            imageSrc: "",
            title: "Autoresponders",
            description:
              "Send marketing emails without even lifting a finger. With our autoresponders your contacts will always feel connected.",
          },
          {
            imageSrc: "",
            title: "Send Time Optimization",
            description:
              "Our AI-powered system will maximize your engagement by sending emails at the perfect time.",
          },
          {
            imageSrc: "",
            title: "Real-time reports",
            description: "Keep tabs on how your campaigns are performing.",
          },
          {
            imageSrc: "",
            title: "Landing Pages",
            description:
              "Build your contacts list even faster thanks to the modern landing pages that help you grow your business. You can choose from the various pre-designed templates or build your own ideas.",
          },
          {
            imageSrc: "",
            title: "Email Automations",
            description:
              "Get even more engaged with your audience with personalized email flow triggered by real-time behaviour. With a proper email flow, your communication can be even more efficient and your relationships can be stronger.",
          },
        ]}
      />
      <Pricing
        description="We guarantee the best pricing, if you find it cheaper, we will match it."
        plans={[
          {
            name: "Basic",
            price: ["Rs 749", "/month"],
            description:
              "Perfect for startups, and small newsletters and product releases.",
            features: [
              "1000 Contacts",
              "Unlimited Emails",
              "Fast Delivery Speed",
              "Custom Fields for Personalization",
              "Campaign Creator",
              "Landing Page Editor",
              "Segmentation",
              "Analytics",
              "Automatic Contact Checker",
            ],
            url: "https://techbud.in/sign-up",
          },
          {
            name: "Premium",
            price: ["Rs 1799", "/month"],
            description:
              "Perfect for startups, and small newsletters and product releases.",
            features: [
              "2500 Contacts",
              "Unlimited Emails",
              "Fast Delivery Speed",
              "Custom Fields for Personalization",
              "Campaign Creator",
              "Landing Page Editor",
              "Segmentation",
              "Analytics",
              "Automatic Contact Checker",
            ],
            url: "https://techbud.in/sign-up",
          },
          {
            name: "Advanced",
            price: ["Rs 3499", "/month"],
            description:
              "Perfect for startups, and small newsletters and product releases.",
            features: [
              "5000 Contacts",
              "Unlimited Emails",
              "Fast Delivery Speed",
              "Custom Fields for Personalization",
              "Campaign Creator",
              "Landing Page Editor",
              "Segmentation",
              "Analytics",
              "Automatic Contact Checker",
            ],
            url: "https://techbud.in/sign-up",
          },
          {
            name: "Custom",
            price: ["", ""],
            description:
              "Perfect for Very Small Users, and Large Enterprises and Mega Product Releases...",
            features: [
              "Custom Contacts",
              "Unlimited Emails",
              "Fast Delivery Speed",
              "Custom Fields for Personalization",
              "Campaign Creator",
              "Landing Page Editor",
              "Segmentation",
              "Analytics",
              "Automatic Contact Checker",
            ],
            url: "https://techbud.in/sign-up",
          },
        ]}
      />
      <MainFeature
        subheading="Reliable"
        heading="Fast Delivery Speed with Simple Interface for Ease of Access"
        description="Use the power of our comprehensive set of modern tools and smart solutions that help you scale your business and build deeper relationships with your customers."
        imageSrc={serverRedundancyIllustrationImageSrc}
        buttonRounded={false}
        primaryButtonUrl=""
      />
      <MainFeature
        subheading="Trust"
        heading="Let your brand shine through with easy-to-use design tools and flexible templates."
        description="Our AI-powered Creative Assistant will even generate custom designs for you in seconds."
        imageSrc={serverSecureIllustrationImageSrc}
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonUrl=""
      />
      <Testimonial description="" />
      <Footer />
    </AnimationRevealPage>
  );
};
