import React, { useState } from "react";
import EmptyList from "../components/EmptyList";
import BlogList from "../components/BlogList";
import { blogList } from "../components/Data";
import Dark from "components/headers/dark";
import "./BlogHome.css";
import { Helmet } from "react-helmet";

const BlogHome = () => {
  const [blogs, setBlogs] = useState(blogList);  

  return (
    <div>
      <Helmet>
        <title>How to Build Your Brand and Scale your Business | Techud</title>
        <meta
          name="Brand Building and Scaling tips from professionals | Techud"
          content="Interviews, articles and todos to learn about brand building and scaling your startup"
        />
        <meta property="og:site_name" content="TechBud" />
        <meta
          property="og:title"
          content="Brand Building and Scaling tips from professionals | Techud"
        />
        <meta
          property="og:description"
          content="Interviews, articles and todos to learn about brand building and scaling your startup"
        />
        <meta
          property="og:image"
          content="https://techbud.in/techbud_final_small.png"
        />
      </Helmet>
      <br></br>
      <Dark />
      <br></br>
      <center>
        <strong>
          <div className="blog-heading">Blogs</div>
        </strong>
      </center>
      <br></br>
      {/* Blog List & Empty View */}
      {!blogs.length ? <EmptyList /> : <BlogList blogs={blogs} />}
    </div>
  );
};

export default BlogHome;
