import React, { useState } from "react";
import logo from "../images/techbud_final_small.png";
import axios from "../Axios";
import { useParams } from "react-router-dom";
import tw from "twin.macro";
import HeaderBase, {
  LogoLink as LogoLinkBase,
  NavLinks,
  NavLink as NavLinkBase,
  PrimaryLink as PrimaryLinkBase,
} from "../components/headers/dark";
import "../components/hero/dropdown.css";
import {
  Container as ContainerBase,
  Content2Xl,
} from "components/misc/Layouts.js";
import { SectionHeading } from "components/misc/Headings.js";
import { SectionDescription } from "components/misc/Typography.js";
import { PrimaryButton as PrimaryButtonBase } from "components/misc/Buttons.js";
import logoImageSrc from "images/white_techbud_final_small2.png";

import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line
import { Subheading as SubheadingBase } from "components/misc/Headings.js";
import { ContentWithPaddingXl as ContentBase } from "components/misc/Layouts.js";
const PrimaryBackgroundContainer = tw.div`-mx-8 px-8 bg-primary-900 text-gray-100`;
const Header = tw(HeaderBase)`max-w-none -mt-8 py-8 -mx-8 px-8`;
const NavLink = tw(
  NavLinkBase
)`lg:text-gray-100 lg:hocus:text-gray-300 lg:hocus:border-gray-100`;
const LogoLink = tw(LogoLinkBase)`text-gray-100 hocus:text-gray-300`;
const PrimaryLink = tw(
  PrimaryLinkBase
)`shadow-raised lg:bg-primary-400 lg:hocus:bg-primary-500`;

const Container = tw(ContainerBase)``;
const Heading = tw(
  SectionHeading
)`max-w-3xl lg:max-w-4xl lg:text-left leading-tight`;
const Description = tw(
  SectionDescription
)`mt-4 max-w-2xl text-gray-100 lg:text-base mx-auto lg:mx-0`;

//alag

const ContentWithPaddingXl = tw(
  ContentBase
)`relative z-10 mx-auto px-0 py-10 sm:px-6 md:px-8 lg:px-12 xl:px-24 sm:py-20 flex flex-col max-w-screen-xl`;
const HeaderContainer = tw.div`mt-10 w-full flex flex-col items-center`;
const Subheading = tw(SubheadingBase)`mb-4 text-gray-100`;

const PlansContainer = tw.div`mt-16 flex flex-col items-center lg:flex-row lg:items-stretch lg:justify-between text-gray-900 font-medium`;
const Plan = styled.div`
  ${tw`w-full max-w-sm bg-white rounded-lg shadow-sm py-10 px-6 sm:px-10 lg:px-6 lg:py-10 xl:p-10 mx-3 flex flex-col justify-between mt-16 first:mt-0 lg:mt-0 shadow-raised`}
`;

const PlanHeader = styled.div`
  .nameAndFeaturedContainer {
    ${tw`flex flex-wrap flex-col sm:flex-row justify-between items-center`}
  }
  .name {
    ${tw`lg:text-lg xl:text-xl font-bold uppercase tracking-wider mr-3`}
  }
  .featuredText {
    ${tw`text-xs font-bold px-3 rounded py-2 uppercase bg-green-300 text-green-900 leading-none mt-4 sm:mt-0 w-full sm:w-auto text-center`}
  }
  .pricingContainer {
    ${tw`mt-6 flex items-end justify-between`}
    .currentPrice {
      ${tw`text-lg font-bold leading-none`}
      .bigText {
        ${tw`text-3xl font-bold`}
      }
    }
    .oldPrice {
      ${tw`text-gray-500 text-lg line-through hidden sm:block`}
    }
  }
  .description {
    ${tw`mt-8 font-medium text-gray-700 lg:text-sm xl:text-base`}
  }
`;
const PlanFeatures = styled.ul`
  ${tw`mt-10 flex-1 border-t lg:-mx-6 -mx-6 sm:-mx-10 py-10 px-6 sm:px-10 lg:p-6 xl:-mx-10 xl:p-10`}
  .feature {
    ${tw`flex items-start mt-6 first:mt-0`}
    .icon {
      ${tw`w-6 h-6 text-primary-500 flex-shrink-0`}
    }
    .text {
      ${tw`font-semibold text-primary-900 tracking-wide ml-3`}
    }
  }
`;

const PlanAction = tw.div`mt-4`;
const ActionButton = styled(PrimaryButtonBase)`
  ${tw`block text-center text-sm font-semibold tracking-wider w-full text-gray-100 bg-primary-500 px-6 py-4 rounded hover:bg-primary-700 focus:shadow-outline focus:outline-none transition-colors duration-300`}
`;

const WhiteBackgroundOverlay = tw.div`absolute inset-x-0 bottom-0 h-1/6 lg:h-1/3 bg-white z-0`;

function EmailPayForm() {
  let { amount } = useParams();
  console.log("amount frm param", amount);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const PayNow = (email, phone, name) => {
    console.log("clicked", amount);
    axios
      .post("/emailpay", {
        email: email,
        phone: phone,
        name: name,
        amount: amount,
      })
      .then((res) => {
        console.log("resp", res.data);
        window.location.href = res.data;
      })
      .catch((error) => console.log(error.response.data));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(amount);
    PayNow(email, phone, name);
  };

  const onChangeEmail = (e) => {
    setEmail(e.target.value);
    console.log(email);
  };

  const onChangePhone = (e) => {
    setPhone(e.target.value);
    console.log(phone);
  };

  const onChangeName = (e) => {
    setName(e.target.value);
    console.log(name);
  };

  const logoLink = (
    <LogoLink href="/">
      <img src={logoImageSrc} alt="Logo" height="20px" />
    </LogoLink>
  );
  const navLinks = [
    <NavLinks key={1}>
      <NavLink href="/">Home</NavLink>
      <NavLink href="/about-us">About</NavLink>
      <div class="dropdown">
        <button class="dropbtn">Our Products</button>
        <div class="dropdown-content">
          <a href="/email-marketing">Email Marketing</a>
          <a href="/website-development">Software Development</a>
          <a href="https://recruitex.in/" target="_blank">Recruitment</a>
        </div>
      </div>
      <NavLink href="/blogs">Blog</NavLink>
      <NavLink href="/contact-us">Contact Us</NavLink>
      <PrimaryLink href="/dashboard">DashBoard</PrimaryLink>
    </NavLinks>,
  ];

  return (
    <div>
      <div style={{ backgroundColor: "#3c0c98", paddingTop: "12px" }}>
        <Content2Xl>
          <Header logoLink={logoLink} links={navLinks} />
        </Content2Xl>
      </div>

      <div>
        <div class="background-su" style={{ marginTop: "80px" }}>
          <div class="shape"></div>
          <div class="shape"></div>
        </div>
        <form
          className="signupForm"
          onSubmit={handleSubmit}
          style={{ marginTop: "80px" }}
        >
          <center>
            <img src={logo} alt="logo" width="200 vw" />
          </center>
          <h4>Techbud Campaigns Payment Page</h4>

          <label for="name" className="signupLabel">
            Name
          </label>
          <input
            type="text"
            placeholder="Name"
            id="name"
            className="input1"
            onChange={onChangeName}
          />

          <label for="email" className="signupLabel">
            Email <br />
            <p style={{ fontSize: "11px" }}>
              (Please use business email, like support@yourdomain.com)
            </p>
          </label>
          <input
            type="email"
            placeholder="Email"
            id="email"
            className="input1"
            onChange={onChangeEmail}
          />
          <label for="phone" className="signupLabel">
            Phone <br />
            <p style={{ fontSize: "11px" }}>
              (Please enter first 10 digits only)
            </p>
          </label>
          <input
            type="number"
            placeholder="Phone Number"
            id="phone number"
            className="input1"
            onChange={onChangePhone}
          />

          <button className="signupButton" type="submit">
            Pay Now
          </button>
        </form>
      </div>
    </div>
  );
}

export default EmailPayForm;
