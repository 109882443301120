import React from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/TwoColumnWithFeaturesAndTestimonial.js";
import Features from "components/features/ThreeColWithSideImage.js";
import MainFeature from "components/features/TwoColWithTwoHorizontalFeaturesAndButton.js";
import FeatureStats from "components/features/ThreeColCenteredStatsPrimaryBackground.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndRating.js";
import GetStarted from "components/cta/GetStartedLight.js";
import Footer from "components/footers/FiveColumn.js";
import styled from "styled-components";
import Backedby from "components/Backedby";
const HighlightedText = tw.span`text-primary-500`;

export const NavLinks = tw.div`inline-block`;

/* hocus: stands for "on hover or focus"
 * hocus:bg-primary-700 will apply the bg-primary-700 class on hover or focus
 */
export const NavLink = tw.a`
  text-lg my-2 lg:text-sm lg:mx-6 lg:my-0
  font-semibold tracking-wide transition duration-300
  pb-1 border-b-2 border-transparent hover:border-primary-500 hocus:text-primary-500
`;

export const LogoLink = styled(NavLink)`
  ${tw`flex items-center font-black border-b-0 text-2xl! ml-0! -mb-12 z-auto`};

  img {
    ${tw`h-10 z-10`}
  }
`;

export default () => {
  return (
    <AnimationRevealPage>
      <Hero />
      <FeatureStats description="" />
      <Features
        heading={
          <>
            Our <HighlightedText>Products</HighlightedText>
          </>
        }
        description="Choosing the right people is difficult, but with us we help you find them."
        subheading="Products"
        cards={[
          {
            title: "Email Marketing Platform",
            description:
              "Why send boring text emails when you can send designer emails and track the results and retarget, and convert people to customers all from our dashboard?",
            imageSrc: "",
          },
          {
            title: "Membership Management",
            description:
              "We love to see you having lots of customers and would love to assist you in maintaining them as well.",
            imageSrc: "",
          },
          {
            title: "Website Development",
            description:
              "A new startup idea? Let TechBud bring it to life. We aim to help individuals, partners as well as big firms to showcase your ideas to the real world and bring in your customers.",
            imageSrc: "",
          },
          {
            title: "Social Media Marketing",
            description:
              "Building Social media has the most merits, but a little complicated, don’t worry we got you covered.",
            imageSrc: "",
          },
        ]}
      />
      <Backedby/>
      <MainFeature
        heading={
          <>
            Services built for <HighlightedText>You</HighlightedText>
          </>
        }
        description="TechBud believes that we all deserve a chance to get noticed and market ourselves to promote our lives' work. We just want to remove that burden and help you achieve your dreams."
        primaryButtonUrl="./contact-us"
      />
      <Testimonial
        heading={
          <>
            Our Clients <HighlightedText>Loved Us</HighlightedText>
          </>
        }
        description=""
      />
      <GetStarted secondaryButton={false} />
      <Footer />
    </AnimationRevealPage>
  );
};
