import axios from "axios";
import https from "https";

const instance = axios.create({
  baseURL: "https://emailpayment.techbud.in/",
  httpsAgent: new https.Agent({
    rejectUnauthorized: false,
  }),
});

export default instance;
