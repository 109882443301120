import React from "react";
import AnimationRevealPage from "helpers/AnimationRevealPage.js";
import tw from "twin.macro";
import styled from "styled-components"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import Header from "components/headers/dark.js";
import Footer from "components/footers/FiveColumn.js";
import MainFeature1 from "components/features/TwoColWithButton.js";

const Subheading = tw.span`uppercase tracking-wider text-sm`;
export default () => {
  return (
    <AnimationRevealPage>
      <Header />
      <MainFeature1
        subheading={<Subheading>About TechBud</Subheading>}
        heading="We are a modern development agency"
        description="We are a remote design agency based in New Delhi, working with clients around the world. As passionate designers, developers and freelancers, we love building products that are easy to use, accessible, engaging, and delightful."
        buttonRounded={false}
        primaryButtonText="Contact Us"
        primaryButtonUrl="./contact-us"
        imageSrc="https://images.unsplash.com/photo-1519389950473-47ba0277781c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=768&q=80"
      />
      <MainFeature1
        subheading={<Subheading>Our Vision</Subheading>}
        heading="Our Vision"
        description="We at Techbud put together a team of passionate minds to transform your visions into reality imparting an awesome digital experience for your users."
        displayButton={ false }
      />
      <Footer />
    </AnimationRevealPage>
  );
};
