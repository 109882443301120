import React, { useState } from "react";
import axios from "axios";
import Dark from "components/headers/dark";
import "./EmailVerifyer.css";

function EmailVerifyer() {
  const [email, setEmail] = useState("");
  const [result, setResult] = useState("");
  const [reason, setReason] = useState("");
  const apikey =
    "AFA1F78AB82560523C6A94F331BD2DF7699F6D0E7A06176AB3D39A80BAB62195FDE0F26E3ECF20FD8D4FA6A9B03F2AA3";
  const VerifyEmail = async (e) => {
    e.preventDefault();
    await axios
      .get(
        `https://campaigns.techbud.in/v2/email/verify?apikey=${apikey}&email=${email}`
      )
      .then((res) => {
        console.log(res.data.data);
        setReason(res.data.data.reason);
        setResult(res.data.data.result);
      });
  };

  const handleChange = (e) => {
    setResult("");
    setReason("");
    setEmail(e.target.value);
    console.log(email);
  };

  function onSubmit(token) {
    document.getElementById("demo-form").submit();
  }

  return (
    <div>
      <Dark />
      <div className="body">
        <div></div>
        <div>
          <form onSubmit={VerifyEmail} className="form">
            <center>
              <label>
                <strong>Type Email Address to Verify</strong>
              </label>
            </center>
            <input
              name="email"
              type="text"
              placeholder="Type Email Address to Verify"
              onChange={handleChange}
              className="inputfield"
            />
            <br />
            <button
              class="g-recaptcha button"
              data-sitekey="6Lfv5GweAAAAAMySTyQv4KJNxM6NoqNEJTRskw7S"
              data-callback="onSubmit"
              data-action="submit"
            >
              Verify
            </button>
          </form>
          <br></br>
          <br></br>
          <br></br>
          <strong>{email ? "Email:" : ""}&nbsp;&nbsp;&nbsp;</strong>
          {email ? email : ""}
          <br />
          <strong>{result ? "Result:" : ""} &nbsp;&nbsp;&nbsp;</strong>
          {result ? result : ""}
          <br />
          <strong>{reason ? "Reason:" : ""}&nbsp;&nbsp;&nbsp;</strong>
          {reason ? reason : ""}
          <br />
        </div>
        <div></div>
      </div>
    </div>
  );
}

export default EmailVerifyer;
