import React from "react";
import tw from "twin.macro"; //eslint-disable-line
import { css } from "styled-components/macro"; //eslint-disable-line
import AnimationRevealPage from "helpers/AnimationRevealPage.js";

import Hero from "components/hero/BackgroundAsImage.js";
import Features from "components/features/DashedBorderSixFeatures";
import MainFeature from "components/features/TwoColSingleFeatureWithStats.js";
import MainFeature2 from "components/features/TwoColWithTwoFeaturesAndButtons.js";
import Portfolio from "components/cards/PortfolioTwoCardsWithImage.js";
import Testimonial from "components/testimonials/TwoColumnWithImageAndProfilePictureReview.js";
import ContactUsForm from "components/forms/TwoColContactUsWithIllustration.js";
import Footer from "components/footers/FiveColumn.js";
import Pricing from "components/pricing/ThreePlansWithHalfPrimaryBackground.js";

export default () => (
  <AnimationRevealPage>
    <Hero />
    <MainFeature
      subheading="Who We are"
      description="If you’re a small business owner or even a large organization in doubt about having a website, We’ll save you some time — you need one, A good one. A professionally designed, lead-catching, sales-increasing, brand-differentiating website. And we at techbud help you do that. From being offline to online, we assist you at every step. We have worked for many industries like E-Commerce, Education, Recruitment, Real-Estate, Construction, Automobiles, Banking, Healthcare, Hospitality, Electronic Media, and many more."
      primaryButtonText="Contact Us"
      primaryButtonUrl="./contact-us"
    />
    <Features />
    <MainFeature2
      description="We offer many different solutions for website development, which based on CMS and Coding. Our team develop all industries website solution according to business requirements. Our experts provide the right consultation by explaining the advantages of different technologies and platforms, which help the business owners to select the right from them."
      primaryButtonText="Contact Us"
      primaryButtonUrl="./contact-us"
    />
    <Portfolio description="The best web design and development features at the best price. We have a team of skilled Web Designer and Development to manage your website as a business asset." />
    <Testimonial
      subheading="Testimonials"
      heading={
        <>
          Our Clients <span tw="text-primary-500">Love Us.</span>
        </>
      }
      description="Here are what some of our amazing customers are saying about our marketing professionals."
      testimonials={[
        {
          imageSrc:
            "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc: "https://msmebusinessforum.com/photo/l1.jpg",
          quote:
            "TechBud made my website and helped me in Website Designing, Social Media Marketing and Email-Marketing, We had taken their full digital marketing plan and we just love it. We have grown exponentially with their help",
          customerName: "Ravi Nandan Sinha",
          customerTitle: "Director, Ravi Nandan Sinha",
        },
        {
          imageSrc:
            "https://images.unsplash.com/photo-1557804506-669a67965ba0?ixlib=rb-1.2.1&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "https://media-exp1.licdn.com/dms/image/C4E03AQGAFvah8XNMww/profile-displayphoto-shrink_200_200/0/1516983852447?e=1634169600&v=beta&t=2fNEf89RLbW9SXAhV_0q26TMAozNAlCb4UpMKTxm-Ng",
          quote:
            "I met TechBud, because of the website work that we posted for our non profit organization. They were provided with the work to build us a website and do digital marketing for us. I wish TechBud, all the best",
          customerName: "Khagendra Tiwari",
          customerTitle: "Founder, Samaksh NGO",
        },
        {
          imageSrc:
            "https://images.unsplash.com/photo-1522202176988-66273c2fd55f?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1024&q=80",
          profileImageSrc:
            "http://www.kaifmahmood.com/wp-content/uploads/2021/08/rofile-3-1.jpg",
          quote:
            "I have made use of Techud’s services intermittently over the last six months for making changes to my website and resolving errors that come up on the site. They have been efficient and skillful in accomplishing the tasks that need to be done, even when other professionals have been unable to understand how to do them. TechBud has completed the work judiciously and within the stipulated time. I am very happy to recommend TechBud.",
          customerName: "Kaif Mahmood",
          customerTitle: "Psychologist, New Delhi",
        },
      ]}
      textOnLeft={true}
    />

    {/* <Pricing
      subheading="Pricing"
      heading=""
      description=""
      plans={[
        {
          name: "Basic",
          price: ["Rs 10,000"],
          description: "Perfect for when you want to host your personal blog or a hobby side project.",
          features: [
            "Hosting for 1 Year",
            "Domain for 1 Year",
            "1 Professional Email",
            "4 Page Website",
            "SEO optimized with images",
            "Social Media Creation with 3 posts"
          ],
          url: "https://razorpay.com/payment-button/pl_HpNnEUShrj2BED/view/?utm_source=payment_button&utm_medium=button&utm_campaign=payment_button"
        },
        {
          name: "Business",
          price: ["Rs 15,000"],
          description: "Perfect for hosting blogs with lots of traffic or heavy development projects.",
          features: [
            "Hosting for 1 Year",
            "Domain for 1 Year",
            "1 Professional Email",
            "8 Page Website",
            "Image CDN for optimized delivery",
            "SEO optimized with images",
            "Social Media Creation with 5 posts"
          ],
          url: "https://razorpay.com/payment-button/pl_HpNqrEEcWkk7jU/view/?utm_source=payment_button&utm_medium=button&utm_campaign=payment_button"
        },
      ]}
    />
    <Pricing
      subheading=""
      heading=""
      description=""
      plans={[
        {
          name: "Advanced",
          price: ["Rs 25,000"],
          description: "Perfect for Ecommerce Websites.",
          features: [
            "Hosting for 1 Year",
            "Domain for 1 Year",
            "5 Page Website with 20 products",
            "Image CDN for optimized delivery",
            "SEO optimized with images",
            "Social Media Creation with 5 posts"
          ],
          url: "https://razorpay.com/payment-button/pl_HpNsWs6hrBlYQO/view/?utm_source=payment_button&utm_medium=button&utm_campaign=payment_button"
        },
        {
          name: "Custom",
          price: [""],
          description: "Have a business plan, and want to create a custom website for your needs.",
          features: [
            "Hosting for 1 Year",
            "Domain for 1 Year",
            "Custom number Page Website",
            "Image CDN for optimized delivery",
            "SEO optimized with images",
            "Social Media Creation with 5 posts"
          ],
          url: "https://razorpay.com/payment-button/pl_HpNw2lT47NqKJQ/view/?utm_source=payment_button&utm_medium=button&utm_campaign=payment_button"
        },
      ]}
  /> */}
    <ContactUsForm description="" />
    <Footer />
  </AnimationRevealPage>
);
