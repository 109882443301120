import React from "react";
import tw from "twin.macro";
import styled from "styled-components";
import { css } from "styled-components/macro"; //eslint-disable-line

import LogoImage from "images/techbud_final_small.png";
import { ReactComponent as FacebookIcon } from "images/facebook-icon.svg";
import { ReactComponent as LinkedinIcon } from "images/linkedin-icon.svg";
import { ReactComponent as InstagramIcon } from "images/instagram-seeklogo_white.svg";

const Container = tw.div`relative bg-gray-200 text-gray-700 -mb-8 -mx-8 px-8 py-20 lg:py-24`;
const Content = tw.div`max-w-screen-xl mx-auto relative z-10`;
const SixColumns = tw.div`flex flex-wrap text-center sm:text-left justify-center sm:justify-start md:justify-between -mt-12`;

const Column = tw.div`px-4 sm:px-0 sm:w-1/4 md:w-auto mt-12`;

const ColumnHeading = tw.h5`uppercase font-bold`;
const CompanyColumn = tw.div`text-center md:text-left mb-16 lg:mb-0 w-full lg:w-1/5`;
const CompanyAboutText = tw.p`mt-4 max-w-xs font-medium text-sm mx-auto lg:mx-0 lg:mr-4 leading-loose text-center lg:text-left`;

const LinkList = tw.ul`mt-6 text-sm font-medium`;
const LinkListItem = tw.li`mt-3`;
const Link = tw.a`border-b-2 border-transparent hocus:border-gray-700 pb-1 transition duration-300`;

const Divider = tw.div`my-16 border-b-2 border-gray-300 w-full`;

const ThreeColRow = tw.div`flex flex-col md:flex-row items-center justify-between`;

const LogoContainer = tw.div`flex items-center justify-center md:justify-start`;

const CopywrightNotice = tw.p`text-center text-sm sm:text-base mt-8 md:mt-0 font-medium text-gray-500`;

const SocialLinksContainer = tw.div`mt-8 md:mt-0 flex`;
const SocialLink = styled.a`
  ${tw`cursor-pointer p-2 rounded-full bg-gray-900 text-gray-100 hover:bg-gray-700 transition duration-300 mr-4 last:mr-0`}
  svg {
    ${tw`w-4 h-4`}
  }
`;

export default () => {
  return (
    <Container>
      <Content>
        <SixColumns>
          <CompanyColumn>
            <LogoContainer>
              <img src={LogoImage} alt="techbud-logo" />
            </LogoContainer>
            <CompanyAboutText>
              TechBud assists you in building your company’s presence online. We
              aim to help you reach out to your audience the fastest way.
            </CompanyAboutText>
          </CompanyColumn>
          <Column>
            <ColumnHeading>Menu</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="/">Home</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/about-us">About</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/website-development">Website Developement</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/email-marketing">Email Marketing</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/blog">Blog</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="/contact-us">Contact Us</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Legal</ColumnHeading>
            <LinkList>
              <LinkListItem>
                <Link href="termsandcondition">Terms of Service</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="privacypolicy">PrivacyPolicy</Link>
              </LinkListItem>
              <LinkListItem>
                <Link href="cookiepolicy">Cookie Policy</Link>
              </LinkListItem>
            </LinkList>
          </Column>
          <Column>
            <ColumnHeading>Contact Info</ColumnHeading>
            <LinkList>+91 8851319469</LinkList>
            <LinkList>support@techbud.in</LinkList>
          </Column>
        </SixColumns>
        <Divider />
        <ThreeColRow>
          <CompanyColumn>
            {/* <LogoContainer>
              <img src={LogoImage} alt="techbud-logo" />
            </LogoContainer> */}
          </CompanyColumn>
          <CopywrightNotice>
            &copy; 2019 TechBud All Rights Reserved.
          </CopywrightNotice>
          <SocialLinksContainer>
            <SocialLink
              href="https://www.instagram.com/techbudindia/"
              target="_blank"
            >
              <InstagramIcon />
            </SocialLink>
            <SocialLink
              href="https://www.facebook.com/techbudIndia"
              target="_blank"
            >
              <FacebookIcon />
            </SocialLink>
            <SocialLink
              href="https://www.linkedin.com/company/techbudindia/"
              target="_blank"
            >
              <LinkedinIcon />
            </SocialLink>
          </SocialLinksContainer>
        </ThreeColRow>
      </Content>
    </Container>
  );
};
